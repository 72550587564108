import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environment';
import { AuthConfig, MtCanLoadAuthGuard } from '@mt-ng2/auth-module';
import { AdminAccessComponent, ForgotPasswordComponent, ResetPasswordComponent } from '@mt-ng2/login-module';
import { StudentAccessComponent } from 'student-portal/custom-login/student-access/student-access.component';
import { StudentLoginComponent } from 'student-portal/custom-login/student-login/student-login.component';
import { StudentNoAccessComponent } from 'student-portal/custom-login/student-no-access/student-no-access.component';
import { appPaths } from './student-portal/default-routes/app-paths.library';

const studentRoutes: Routes = [
	{
		canActivate: [
			() => {
				const authConfig = inject(AuthConfig);
				authConfig.paths.homePath = '/landing';
				authConfig.paths.loginPath = '/en-la-casa/login';
			},
		],
		path: 'en-la-casa',
		loadChildren: () => import('./student-portal/en-la-casa/en-la-casa.module').then((m) => m.EnLaCasaModule),
	},
	{
		canActivate: [
			() => {
				const authConfig = inject(AuthConfig);
				authConfig.paths.homePath = '/landing';
				authConfig.paths.loginPath = '/en-la-casa/login';
			},
		],
		path: 'en-la-casa',
		loadChildren: () => import('./student-portal/en-la-casa/en-la-casa.module').then((m) => m.EnLaCasaModule),
	},
	{
		canActivate: [
			() => {
				const authConfig = inject(AuthConfig);
				authConfig.paths.homePath = '/landing';
				authConfig.paths.loginPath = '/login';
				return true;
			},
		],

		path: appPaths.studentLogin,
		component: StudentLoginComponent,
	},
	{ path: 'noaccess', component: StudentNoAccessComponent },
	{ path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
	{ path: appPaths.studentAccess, component: StudentAccessComponent },
	{
		canActivate: [
			() => {
				const authConfig = inject(AuthConfig);
				authConfig.paths.homePath = '/landing';
				authConfig.paths.loginPath = '/login';
				return true;
			},
		],
		canLoad: [MtCanLoadAuthGuard],
		path: '',
		loadChildren: () => import('./student-portal/student-portal.module').then((m) => m.StudentPortalModule),
	},
];

const adminRoutes: Routes = [
	{
		path: appPaths.adminAccess,
		component: AdminAccessComponent,
		title: 'Admin Access',
	},
	{ path: appPaths.forgotPassword, component: ForgotPasswordComponent, title: 'Forgot Password' },

	{ path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
	{
		canActivate: [
			() => {
				const authConfig = inject(AuthConfig);
				authConfig.paths.loginPath = '/login';
				return true;
			},
		],
		path: '',
		loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule),
	},
];

const isStudentDomain = window.location.host === environment.studentDomain;

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(isStudentDomain ? studentRoutes : adminRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
